import React, { useState, useCallback } from 'react';
import { I18nTranslateContext, I18nLanguageContext } from './I18nContext';

const catalog = new Map([
  [
    'meta.description',
    new Map([
      ['fr', 'Jouez à Tixid en ligne avec vos amis !'],
      ['en', 'Play Tixid online with your friends !'],
      ['de', 'Spielen Sie Tixid online mit Ihren Freunden !'],
      ['it', 'Gioca a Tixid online con i tuoi amici !'],
      ['es', '¡Juega a Tixid en línea con tus amigos!'],
      ['ca', 'Juga a Tixid en línia amb els teus amics!'],
      ['pt', 'Jogue Tixid online com seus amigos!'],
      ['th', 'เล่น Tixid ออนไลน์กับเพื่อนของคุณ!'],
      ['tr', 'Arkadaşlarınla online Tixid oyna!'],
      ['ja', '友達とオンラインでディクシットを遊ぼう！'],
    ]),
  ],
  [
    'info.update',
    new Map([
      ['fr', '🙌 Mise à jour ! 🙌'],
      ['en', '🙌 New update ! 🙌'],
      ['de', '🙌 Neues Update ! 🙌'],
      ['it', '🙌 Nuovo aggiornamento ! 🙌'],
      ['es', '🙌 Nueva actualización ! 🙌'],
      ['ca', '🙌 Nova actualització ! 🙌'],
      ['pt', '🙌 Nova atualização! 🙌'],
      ['th', '🙌 อัปเดตใหม่! 🙌'],
      ['tr', '🙌 Yeni güncelleme! 🙌'],
      ['ja', '🙌 新しいアップデート！🙌'],
    ]),
  ],
  [
    'info.message',
    new Map([
      ['fr', 'Dark mode !'],
      ['en', 'Dark mode !'],
      ['de', 'Dark mode !'],
      ['it', 'Dark mode !'],
      ['es', 'Dark mode !'],
      ['ca', 'Mode fosc !'],
      ['pt', 'Modo escuro!'],
      ['th', 'โหมดมืด!'],
      ['tr', 'Karanlık mod!'],
      ['ja', 'ダークモード！'],
    ]),
  ],
  [
    'welcome.home',
    new Map([
      ['fr', 'Bienvenue'],
      ['en', 'Welcome'],
      ['de', 'Herzlich Willkommen'],
      ['it', 'Benvenuto'],
      ['es', 'Bienvenido'],
      ['ca', 'Us donem la benvinguda'],
      ['pt', 'Bem-vindo'],
      ['th', 'ยินดีต้อนรับ'],
      ['tr', 'Hoş geldiniz'],
      ['ja', 'ようこそ'],
    ]),
  ],
  [
    'welcome.change-username',
    new Map([
      ['fr', 'Changer mon pseudo'],
      ['en', 'Change my username'],
      ['de', 'Pseudo ändern'],
      ['it', 'Cambia pseudo'],
      ['es', 'Cambiar pseudo'],
      ['ca', "Canvia el nom d'usuari"],
      ['pt', 'Alterar nome de usuário'],
      ['th', 'เปลี่ยนชื่อผู้ใช้ของฉัน'],
      ['tr', 'Kullanıcı adımı değiştir'],
      ['ja', 'ユーザー名を変更'],
    ]),
  ],
  [
    'lobby-infos.no-waiting-games',
    new Map([
      ['fr', 'Aucune partie en attente de joueurs'],
      ['en', 'No games waiting for players'],
      ['de', 'Keine Spiele warten auf Spieler'],
      ['it', 'Nessuna partita in attesa di giocatori'],
      ['es', 'No hay partidas esperando jugadores'],
      ['ca', 'No hi ha partides que esperin jugadors.'],
      ['pt', 'Nenhum jogo aguardando jogadores'],
      ['th', 'ไม่มีเกมที่รอผู้เล่น'],
      ['tr', 'Oyuncu bekleyen oyun yok'],
      ['ja', '待機中のゲームがありません'],
    ]),
  ],
  [
    'lobby-infos.no-connected-players',
    new Map([
      ['fr', 'Aucun joueur prêt à jouer'],
      ['en', 'No players ready to play'],
      ['de', 'Kein Spieler bereit zu spielen'],
      ['it', 'No giocatore pronto a giocare'],
      ['es', 'No hay jugadores esperando para jugar'],
      ['ca', 'No hi ha jugadors que esperin per a jugar.'],
      ['pt', 'Nenhum jogador pronto para jogar'],
      ['th', 'ไม่มีผู้เล่นที่พร้อมจะเล่น'],
      ['tr', 'Oynamaya hazır oyuncu yok'],
      ['ja', 'プレイ準備ができているプレイヤーがいません'],
    ]),
  ],
  [
    'lobby-infos.game',
    new Map([
      ['fr', 'partie'],
      ['en', 'game'],
      ['de', 'Spiel'],
      ['it', 'Partita'],
      ['es', 'Partida'],
      ['ca', 'partida'],
      ['pt', 'jogo'],
      ['th', 'เกม'],
      ['tr', 'oyun'],
      ['ja', 'ゲーム'],
    ]),
  ],
  [
    'lobby-infos.waiting-players',
    new Map([
      ['fr', 'en attente de joueurs'],
      ['en', 'waiting for players'],
      ['de', 'Warten auf Spieler'],
      ['it', 'Aspettando giocatori'],
      ['es', 'Esperando jugadores'],
      ['ca', "a l'espera que entrin més jugadors"],
      ['pt', 'aguardando jogadores'],
      ['th', 'กำลังรอผู้เล่น'],
      ['tr', 'oyuncuları bekliyor'],
      ['ja', 'プレイヤーを待っています'],
    ]),
  ],
  [
    'lobby-infos.connected-player',
    new Map([
      ['fr', 'joueur prêt à jouer'],
      ['en', 'player ready to play'],
      ['de', 'Spieler spielbereit'],
      ['it', 'Giocatore pronto a giocare'],
      ['es', 'Jugador listo para jugar'],
      ['ca', 'jugador llest per a jugar'],
      ['pt', 'jogador pronto para jogar'],
      ['th', 'ผู้เล่นพร้อมที่จะเล่น'],
      ['tr', 'Oyuncu oynamaya hazır'],
      ['ja', 'プレイ準備ができているプレイヤー'],
    ]),
  ],
  [
    'lobby-infos.connected-players',
    new Map([
      ['fr', 'joueurs prêts à jouer'],
      ['en', 'players ready to play'],
      ['de', 'spielbereite Spieler'],
      ['it', 'Giocatori pronto a giocare'],
      ['es', 'Jugadores listos para jugar'],
      ['ca', 'jugadors llestos per a jugar'],
      ['pt', 'jogadores prontos para jogar'],
      ['th', 'ผู้เล่นพร้อมที่จะเล่น'],
      ['tr', 'Oynamaya hazır oyuncular'],
      ['ja', 'プレイ準備ができているプレイヤー'],
    ]),
  ],
  [
    'lobby-infos.games',
    new Map([
      ['fr', 'parties'],
      ['en', 'games'],
      ['de', 'Spiele'],
      ['it', 'Partite'],
      ['es', 'Partidas'],
      ['ca', 'partides'],
      ['pt', 'jogos'],
      ['th', 'เกม'],
      ['tr', 'oyunlar'],
      ['ja', 'ゲーム'],
    ]),
  ],
  [
    'lobby-infos.no-players-connected',
    new Map([
      ['fr', 'Aucun joueur prêt à jouer'],
      ['en', 'No players ready to play'],
      ['de', 'Kein Spieler bereit zu spielen'],
      ['it', 'No giocatore pronto a giocare'],
      ['es', 'Sin jugadores para jugar'],
      ['ca', 'Sense jugadors per a jugar'],
      ['pt', 'Nenhum jogador pronto para jogar'],
      ['th', 'ไม่มีผู้เล่นพร้อมที่จะเล่น'],
      ['tr', 'Oynamaya hazır oyuncu yok'],
      ['ja', 'プレイ準備ができているプレイヤーがいません'],
    ]),
  ],
  [
    'game-configuration.ending-condition',
    new Map([
      ['fr', 'Condition de fin de partie'],
      ['en', 'End of game condition'],
      ['de', 'Spielende Bedingung'],
      ['it', 'Condizioni di fine della partita'],
      ['es', 'Condiciones para el fin de la partida'],
      ['ca', 'Condicions de fi de la partida'],
      ['pt', 'Condição de término de jogo'],
      ['th', 'เงื่อนไขการจบเกม'],
      ['tr', 'Oyunun bitiş koşulu'],
      ['ja', 'ゲーム終了条件'],
    ]),
  ],
  [
    'game-configuration.value-label',
    new Map([
      ['fr', 'Valeur'],
      ['en', 'Value'],
      ['de', 'Wert'],
      ['it', 'Valore'],
      ['es', 'Valor'],
      ['ca', 'Valor'],
      ['pt', 'Valor'],
      ['th', 'ค่า'],
      ['tr', 'Değer'],
      ['ja', '値'],
    ]),
  ],
  [
    'game-configuration.default',
    new Map([
      ['fr', 'Par défaut'],
      ['en', 'Default'],
      ['de', 'Standardmäßig'],
      ['it', 'Di default'],
      ['es', 'Por defecto'],
      ['ca', 'Per defecte'],
      ['pt', 'Padrão'],
      ['th', 'ค่าเริ่มต้น'],
      ['tr', 'Varsayılan'],
      ['ja', 'デフォルト'],
    ]),
  ],
  [
    'game-configuration.x-times-storyteller',
    new Map([
      ['fr', 'Nombre de fois conteur'],
      ['en', 'Number of times being storyteller'],
      ['de', 'Häufigkeit, Geschichtenerzähler zu sein'],
      ['it', 'Numero di volte Narratore'],
      ['es', 'Número de veces siendo Narrador'],
      ['ca', "Nombre de vegades que s'ha fet de narrador"],
      ['pt', 'Número de vezes como narrador'],
      ['th', 'จำนวนครั้งที่เป็นผู้เล่าเรื่อง'],
      ['tr', 'Anlatıcı olma sayısı'],
      ['ja', 'ストーリーテラーになった回数'],
    ]),
  ],
  [
    'game-configuration.points-limit',
    new Map([
      ['fr', 'Limite de points'],
      ['en', 'Points limit'],
      ['de', 'Punktbegrenzung'],
      ['it', 'Punteggio limite'],
      ['es', 'Límite de puntos'],
      ['ca', 'Límit de punts'],
      ['pt', 'Limite de pontos'],
      ['th', 'ขีดจำกัดคะแนน'],
      ['tr', 'Puan limiti'],
      ['ja', 'ポイントの上限'],
    ]),
  ],
  [
    'game-configuration.value-error',
    new Map([
      ['fr', 'La valeur doit être supérieure à zéro'],
      ['en', 'The value must be superior to zero'],
      ['de', 'Der Wert muss größer als Null sein'],
      ['it', 'Il valore deve essere maggiore di zero'],
      ['es', 'El valor debe ser mayor de cero'],
      ['ca', 'El valor ha de ser més gran de zero'],
      ['pt', 'O valor deve ser maior que zero'],
      ['th', 'ค่าต้องมากกว่าศูนย์'],
      ['tr', 'Değer sıfırdan büyük olmalıdır'],
      ['ja', '値は0より大きくなければなりません'],
    ]),
  ],
  [
    'game-choice.play-now',
    new Map([
      ['fr', 'JOUER !'],
      ['en', 'PLAY!'],
      ['de', 'SPIELEN!'],
      ['it', 'GIOCARE!'],
      ['es', 'A JUGAR!'],
      ['ca', 'Juga una partida'],
      ['pt', 'JOGAR!'],
      ['th', 'เล่นเลย!'],
      ['tr', 'OYNA!'],
      ['ja', '今すぐプレイ！'],
    ]),
  ],
  [
    'game-choice.title',
    new Map([
      ['fr', 'Choix de partie'],
      ['en', 'Game choice'],
      ['de', 'Spielwahl'],
      ['it', 'Scelta di partita'],
      ['es', 'Opciones de partida'],
      ['ca', 'Opcions de la partida'],
      ['pt', 'Escolha do jogo'],
      ['th', 'ตัวเลือกเกม'],
      ['tr', 'Oyun seçimi'],
      ['ja', 'ゲームの選択'],
    ]),
  ],
  [
    'game-choice.create-new-game',
    new Map([
      ['fr', 'Créer une nouvelle partie privée'],
      ['en', 'Create a new private game'],
      ['de', 'Erstelle ein neues privates Spiel'],
      ['it', 'Creare una nuova partita'],
      ['es', 'Crear una nueva partida'],
      ['ca', 'Crea una partida nova'],
      ['pt', 'Criar um novo jogo privado'],
      ['th', 'สร้างเกมส่วนตัวใหม่'],
      ['tr', 'Yeni özel oyun oluştur'],
      ['ja', '新しいプライベートゲームを作成'],
    ]),
  ],
  [
    'game-choice.or',
    new Map([
      ['fr', 'ou'],
      ['en', 'or'],
      ['de', 'oder'],
      ['it', 'o'],
      ['es', 'o'],
      ['ca', 'o'],
      ['pt', 'ou'],
      ['th', 'หรือ'],
      ['tr', 'veya'],
      ['ja', 'または'],
    ]),
  ],
  [
    'game-choice.join-game',
    new Map([
      ['fr', 'Rejoindre une partie :'],
      ['en', 'Join a game :'],
      ['de', 'Mach mit bei einem Spiel :'],
      ['it', 'Unirsi una partita'],
      ['es', 'Unirse a una partida'],
      ['ca', 'Uneix-me a una partida'],
      ['pt', 'Entrar em um jogo:'],
      ['th', 'เข้าร่วมเกม:'],
      ['tr', 'Bir oyuna katıl:'],
      ['ja', 'ゲームに参加：'],
    ]),
  ],
  [
    'game-choice.code-error-empty',
    new Map([
      ['fr', 'Le code ne peut pas être vide'],
      ['en', 'The code cannot be empty'],
      ['de', 'Der Code darf nicht leer sein'],
      ['it', 'Il codice non puo essere vuoto'],
      ['es', 'El código no puede estar vacío'],
      ['ca', "S'ha d'introduir un codi."],
      ['pt', 'O código não pode estar vazio'],
      ['th', 'โค้ดต้องไม่ว่างเปล่า'],
      ['tr', 'Kod boş olamaz'],
      ['ja', 'コードは空にできません'],
    ]),
  ],
  [
    'game-choice.join',
    new Map([
      ['fr', 'rejoindre'],
      ['en', 'join'],
      ['de', 'teilnehmen'],
      ['it', 'Unirsi'],
      ['es', 'Unirse'],
      ['ca', "Uneix-m'hi"],
      ['pt', 'entrar'],
      ['th', 'เข้าร่วม'],
      ['tr', 'katıl'],
      ['ja', '参加'],
    ]),
  ],
  [
    'footer.created-by',
    new Map([
      ['fr', 'Créé par'],
      ['en', 'Created by'],
      ['de', 'Erstellt von'],
      ['it', 'Creato per'],
      ['es', 'Creado por'],
      ['ca', 'Creat per'],
      ['pt', 'Criado por'],
      ['th', 'สร้างโดย'],
      ['tr', 'Oluşturan'],
      ['ja', '作成者'],
    ]),
  ],
  [
    'footer.dixit-world-coming',
    new Map([
      ['fr', 'le jeu officiel, arrive bientôt !'],
      ['en', 'the official game, is coming soon !'],
      ['de', 'Das offizielle Spiel, kommt bald'],
      ['it', 'il gioco ufficiale, arriverà presto'],
      ['es', 'El juego oficial llegará pronto'],
      ['ca', 'el joc oficial arribarà aviat'],
      ['pt', 'o jogo oficial está chegando em breve'],
      ['th', 'เกมอย่างเป็นทางการกำลังจะมาเร็ว ๆ นี้'],
      ['tr', 'resmi oyun yakında geliyor'],
      ['ja', '公式ゲームがもうすぐ登場！'],
    ]),
  ],
  [
    'footer.dixit-world-register-beta',
    new Map([
      ['fr', 'Inscrivez-vous pour la beta'],
      ['en', 'Register for the beta'],
      ['de', 'Registrieren Sie sich für die Beta'],
      ['it', 'Registrati per la beta'],
      ['es', 'Regístrate para la beta'],
      ['ca', 'Registreu-vos per a la beta'],
      ['pt', 'Cadastre-se para o beta'],
      ['th', 'ลงทะเบียนสำหรับรุ่นเบต้า'],
      ['tr', "Beta'ya kayıt olun"],
      ['ja', 'ベータ版に登録'],
    ]),
  ],
  [
    'game.cant-start-game',
    new Map([
      ['fr', 'Impossible de lancer la partie :('],
      ['en', "Can't start the game :("],
      ['de', 'Es ist unmöglich, das Spiel zu starten :('],
      ['it', 'Impossibile avviare la partita'],
      ['es', 'No se puede iniciar la partida'],
      ['ca', 'La partida no pot començar.'],
      ['pt', 'Não é possível iniciar o jogo :('],
      ['th', 'ไม่สามารถเริ่มเกมได้ :('],
      ['tr', 'Oyun başlatılamıyor :('],
      ['ja', 'ゲームを開始できません :('],
    ]),
  ],
  [
    'an-error-has-occured',
    new Map([
      ['fr', 'Une erreur est survenue :('],
      ['en', 'An error has occurred :('],
      ['de', 'Ein Fehler ist aufgetreten :('],
      ['it', 'E verificato un errore :('],
      ['es', 'Ha ocurrido un error :('],
      ['ca', "S'ha produït un error."],
      ['pt', 'Ocorreu um erro :('],
      ['th', 'เกิดข้อผิดพลาด :('],
      ['tr', 'Bir hata oluştu :('],
      ['ja', 'エラーが発生しました :('],
    ]),
  ],
  [
    'refresh-page',
    new Map([
      ['fr', 'Essayez de rafraîchir la page'],
      ['en', 'Try to refresh the page'],
      ['de', 'Versuchen Sie, die Seite zu aktualisieren'],
      ['it', 'Provate a aggiornare la pagina'],
      ['es', 'Trata de recargar la página'],
      ['ca', 'Intenteu recarregar la pàgina.'],
      ['pt', 'Tente atualizar a página'],
      ['th', 'ลองรีเฟรชหน้าเว็บ'],
      ['tr', 'Sayfayı yenilemeyi deneyin'],
      ['ja', 'ページをリロードしてみてください'],
    ]),
  ],
  [
    'game.last-turn',
    new Map([
      ['fr', 'Dernier tour !'],
      ['en', 'Last turn!'],
      ['de', 'Letzte Windung!'],
      ['it', 'Ultimo giro'],
      ['es', 'Último turno!'],
      ['ca', 'Últim torn'],
      ['pt', 'Última rodada!'],
      ['th', 'เทิร์นสุดท้าย!'],
      ['tr', 'Son tur!'],
      ['ja', '最後のターン！'],
    ]),
  ],
  [
    'game.remaining-turns',
    new Map([
      ['fr', 'Tours restants : '],
      ['en', 'Remaining turns: '],
      ['de', 'Verbleibende Spielrunden: '],
      ['it', 'Round rimasti: '],
      ['es', 'Turnos restantes: '],
      ['ca', 'Torns restants: '],
      ['pt', 'Rodadas restantes: '],
      ['th', 'เทิร์นที่เหลือ: '],
      ['tr', 'Kalan turlar: '],
      ['ja', '残りのターン：'],
    ]),
  ],
  [
    'game.restart-game',
    new Map([
      ['fr', 'Relancer la partie'],
      ['en', 'Relaunch the game'],
      ['de', 'Starten Sie das Spiel neu'],
      ['it', 'Riavvia il gioco'],
      ['es', 'Relanzar la partida'],
      ['ca', 'Torna a llançar la partida'],
      ['pt', 'Reiniciar o jogo'],
      ['th', 'เริ่มเกมใหม่อีกครั้ง'],
      ['tr', 'Oyunu yeniden başlat'],
      ['ja', 'ゲームを再開する'],
    ]),
  ],
  [
    'error.oops',
    new Map([
      ['fr', 'Oups...'],
      ['en', 'Oops...'],
      ['de', 'Hoppla...'],
      ['it', 'Oups...'],
      ['es', 'Ups...'],
      ['ca', 'Ui...'],
      ['pt', 'Ops...'],
      ['th', 'อุ๊ปส์...'],
      ['tr', 'Hata...'],
      ['ja', 'おっと...'],
    ]),
  ],
  [
    'error.punish-me',
    new Map([
      ['fr', 'Il va falloir punir le développeur...'],
      ['en', 'The developer has to be punished...'],
      ['de', 'Wir müssen den Entwickler bestrafen...'],
      ['it', 'Dovremo punire il deveolper...'],
      ['es', 'Vamos a castigar al desarrollador...'],
      ['ca', 'Hem de castigar al desenvolupador...'],
      ['pt', 'Precisamos punir o desenvolvedor...'],
      ['th', 'เราต้องลงโทษนักพัฒนา...'],
      ['tr', 'Geliştiriciyi cezalandırmamız gerekiyor...'],
      ['ja', '開発者を罰する必要がありますね...'],
    ]),
  ],
  [
    'game.does-not-exist',
    new Map([
      ['fr', "Aucune partie n'existe pour ce code !"],
      ['en', 'No game was found for this code'],
      ['de', 'Für diesen Code wurde kein Spiel gefunden'],
      ['it', 'No partita per questo codice'],
      ['es', 'No se ha encontrado una partida con este código'],
      ['ca', "No s'ha trobat cap partida amb aquest codi."],
      ['pt', 'Nenhum jogo encontrado para este código!'],
      ['th', 'ไม่พบเกมสำหรับโค้ดนี้!'],
      ['tr', 'Bu kod için oyun bulunamadı!'],
      ['ja', 'このコードに該当するゲームはありません！'],
    ]),
  ],
  [
    'game.error-not-in-game',
    new Map([
      ['fr', "Vous n'êtes pas dans cette partie"],
      ['en', "You're not in that game"],
      ['de', 'Sie sind nicht in diesem Spiel'],
      ['it', 'Non è in questa partita'],
      ['es', 'No estás en esa partida'],
      ['ca', 'No esteu a la partida.'],
      ['pt', 'Você não está neste jogo'],
      ['th', 'คุณไม่ได้อยู่ในเกมนี้'],
      ['tr', 'Bu oyunda değilsiniz'],
      ['ja', 'あなたはそのゲームに参加していません'],
    ]),
  ],
  [
    'game.error-game-full',
    new Map([
      ['fr', 'Ce jeu est déjà complet'],
      ['en', 'This game is full'],
      ['de', 'Dieses Spiel ist bereits voll'],
      ['it', 'Questo gioco è già pieno'],
      ['es', 'Esta partida está completa'],
      ['ca', 'Aquesta partida està completa.'],
      ['pt', 'Este jogo está cheio'],
      ['th', 'เกมนี้เต็มแล้ว'],
      ['tr', 'Bu oyun dolu'],
      ['ja', 'このゲームは満員です'],
    ]),
  ],
  [
    'game.accessing-game',
    new Map([
      ['fr', 'Accès au jeu...'],
      ['en', 'Accessing the game...'],
      ['de', 'Zugang zum Spiel...'],
      ['it', 'Accesso al gioco...'],
      ['es', 'Acceso al juego...'],
      ['ca', 'Accés al joc...'],
      ['pt', 'Acessando o jogo...'],
      ['th', 'กำลังเข้าถึงเกม...'],
      ['tr', 'Oyuna erişiliyor...'],
      ['ja', 'ゲームにアクセス中...'],
    ]),
  ],
  [
    'game.click-if-not-redirected',
    new Map([
      ['fr', "Cliquez ici si vous n'êtes pas redirigés"],
      ['en', "Click here if you're not redirected"],
      ['de', 'Klicken Sie hier, wenn Sie nicht weitergeleitet werden'],
      ['it', 'Fare clic qui se non è rediretta'],
      ['es', 'Haz clic aquí si no te redirige'],
      ['ca', 'Feu clic aquí si no us redirigeix'],
      ['pt', 'Clique aqui se você não for redirecionado'],
      ['th', 'คลิกที่นี่หากคุณไม่ได้ถูกเปลี่ยนหน้า'],
      ['tr', 'Yönlendirilmezsen buraya tıkla'],
      ['ja', 'リダイレクトされない場合はここをクリックしてください'],
    ]),
  ],
  [
    'turn.waiting-for-other-players',
    new Map([
      ['fr', 'En attente des autres joueurs...'],
      ['en', 'Waiting for the other players...'],
      ['de', 'Warten auf die anderen Spieler...'],
      ['it', 'In attesa degli altri giocatori...'],
      ['es', 'Esperando a otros jugadores...'],
      ['ca', "S'espera altres jugadors..."],
      ['pt', 'Aguardando os outros jogadores...'],
      ['th', 'กำลังรอผู้เล่นคนอื่น...'],
      ['tr', 'Diğer oyuncular bekleniyor...'],
      ['ja', '他のプレイヤーを待っています...'],
    ]),
  ],
  [
    'turn.you-are-the-storyteller',
    new Map([
      ['fr', 'Vous êtes le conteur !'],
      ['en', 'You are the storyteller!'],
      ['de', 'Du bist die Geschichtenerzähler*in!'],
      ['it', 'Lei è il narratore'],
      ['es', 'Eres el Narrador!'],
      ['ca', 'Sou el narrador.'],
      ['pt', 'Você é o narrador!'],
      ['th', 'คุณเป็นผู้เล่าเรื่อง!'],
      ['tr', 'Anlatıcı sensin!'],
      ['ja', 'あなたがストーリーテラーです！'],
    ]),
  ],
  [
    'turn.waiting-for-storyteller',
    new Map([
      ['fr', 'En attente du conteur...'],
      ['en', 'Waiting for the storyteller...'],
      ['de', 'Warten auf die Geschichtenerzähler*in...'],
      ['it', 'Aspettando il narratore...'],
      ['es', 'Esperando al Narrador...'],
      ['ca', "S'espera el narrador..."],
      ['pt', 'Aguardando o narrador...'],
      ['th', 'กำลังรอผู้เล่าเรื่อง...'],
      ['tr', 'Anlatıcı bekleniyor...'],
      ['ja', 'ストーリーテラーを待っています...'],
    ]),
  ],
  [
    'turn.other-players-voting',
    new Map([
      ['fr', 'Les autres joueurs sont en train de voter...'],
      ['en', 'Other players are voting...'],
      ['de', 'Andere Spieler stimmen ab...'],
      ['it', 'Gli altri giocatori stanno votando...'],
      ['es', 'Los otros jugadores están votando...'],
      ['ca', 'Els altres jugadors voten...'],
      ['pt', 'Os outros jogadores estão votando...'],
      ['th', 'ผู้เล่นคนอื่นกำลังโหวต...'],
      ['tr', 'Diğer oyuncular oy veriyor...'],
      ['ja', '他のプレイヤーが投票中です...'],
    ]),
  ],
  [
    'turn.error-cant-vote-for-own-card',
    new Map([
      ['fr', 'Vous ne pouvez pas voter pour votre propre carte !'],
      ['en', "You can't vote for your own card !"],
      ['de', 'Sie können nicht für Ihre eigene Karte stimmen !'],
      ['it', 'Non può votare per la propria carta !'],
      ['es', 'No puedes votar por tu propia carta !'],
      ['ca', 'No podeu votar per la vostra carta.'],
      ['pt', 'Você não pode votar em sua própria carta!'],
      ['th', 'คุณไม่สามารถโหวตให้การ์ดของตัวเองได้!'],
      ['tr', 'Kendi kartına oy veremezsin!'],
      ['ja', '自分のカードには投票できません！'],
    ]),
  ],
  [
    'turn.cards-exposed-to-vote',
    new Map([
      ['fr', 'Cartes soumises au vote'],
      ['en', 'Exposed cards'],
      ['de', 'Karten zur Abstimmung gestellt'],
      ['it', 'Carte sottoposte al voto'],
      ['es', 'Cartas mandadas a votación'],
      ['ca', 'Cartes mostrades'],
      ['pt', 'Cartas expostas para votação'],
      ['th', 'เปิดเผยการ์ดให้โหวต'],
      ['tr', 'Oylamaya sunulan kartlar'],
      ['ja', '投票対象のカード'],
    ]),
  ],
  [
    'card.owner',
    new Map([
      ['fr', 'Carte de {$}'],
      ['en', "{$}'s card"],
      ['de', '{$} Karte'],
      ['it', 'Carta di {$}'],
      ['es', 'Carta de {$}'],
      ['ca', 'Carta de {$}'],
      ['pt', 'Carta de {$}'],
      ['th', 'การ์ดของ {$}'],
      ['tr', "{$}'in kartı"],
      ['ja', '{$}のカード'],
    ]),
  ],
  [
    'card-modal.clue',
    new Map([
      ['fr', "Définir l'indice pour cette carte"],
      ['en', 'Define a clue for this card'],
      ['de', 'Definieren Sie einen Hinweis für diese Karte'],
      ['it', "Definisci l'indizio per questa carta"],
      ['es', 'Definir una pista para esta carta'],
      ['ca', 'Escriviu une pista per a la carta.'],
      ['pt', 'Defina uma pista para esta carta'],
      ['th', 'กำหนดเบาะแสสำหรับการ์ดใบนี้'],
      ['tr', 'Bu kart için bir ipucu belirleyin'],
      ['ja', 'このカードに対する手がかりを入力してください'],
    ]),
  ],
  [
    'card-modal.clue-placeholder',
    new Map([
      ['fr', 'indice'],
      ['en', 'clue'],
      ['de', 'Hinweis'],
      ['it', 'indizio'],
      ['es', 'índice'],
      ['ca', 'pista'],
      ['pt', 'dica'],
      ['th', 'คำใบ้'],
      ['tr', 'ipucu'],
      ['ja', 'ヒント'],
    ]),
  ],
  [
    'card-modal.validate',
    new Map([
      ['fr', 'VALIDER'],
      ['en', 'VALIDATE'],
      ['de', 'BESTÄTIGEN'],
      ['it', 'CONVALIDARE'],
      ['es', 'VALIDAR'],
      ['ca', 'Valida'],
      ['pt', 'VALIDAR'],
      ['th', 'ยืนยัน'],
      ['tr', 'ONAYLA'],
      ['ja', '確認'],
    ]),
  ],
  [
    'card-modal.chose-card',
    new Map([
      ['fr', 'CHOISIR CETTE CARTE'],
      ['en', 'CHOOSE THIS CARD'],
      ['de', 'WÄHLEN SIE DIESE KARTE'],
      ['it', 'SCEGLIERE QUESTA CARTA'],
      ['es', 'ESCOGE ESTA CARTA'],
      ['ca', 'Escull aquesta carta'],
      ['pt', 'ESCOLHER ESTA CARTA'],
      ['th', 'เลือกการ์ดนี้'],
      ['tr', 'BU KARTI SEÇ'],
      ['ja', 'このカードを選ぶ'],
    ]),
  ],
  [
    'card-modal.vote-card',
    new Map([
      ['fr', 'VOTER POUR CETTE CARTE'],
      ['en', 'VOTE FOR THIS CARD'],
      ['de', 'ABSTIMMUNG FÜR DIESE KARTE'],
      ['it', 'VOTARE PER QUESTA CARTA'],
      ['es', 'VOTAR POR ESTA CARTA'],
      ['ca', 'Vota per aquesta carta'],
      ['pt', 'VOTAR NESTA CARTA'],
      ['th', 'โหวตให้การ์ดนี้'],
      ['tr', 'BU KARTA OY VER'],
      ['ja', 'このカードに投票する'],
    ]),
  ],
  [
    'card-modal.vote-result',
    new Map([
      ['fr', 'Joueur(s) ayant voté pour cette carte :'],
      ['en', 'Players who voted for this card:'],
      ['de', 'Spieler, die für diese Karte gestimmt haben:'],
      ['it', 'Giocatore(i) avendo votato per questa carta:'],
      ['es', 'Jugador(es) que ha(n) votado por esta carta:'],
      ['ca', 'Jugadors que han votat per la carta:'],
      ['pt', 'Jogadores que votaram nesta carta:'],
      ['th', 'ผู้เล่นที่โหวตให้การ์ดนี้:'],
      ['tr', 'Bu karta oy veren oyuncular:'],
      ['ja', 'このカードに投票したプレイヤー：'],
    ]),
  ],
  [
    'card-modal.no-votes',
    new Map([
      ['fr', "Personne n'a voté pour cette carte."],
      ['en', 'Nobody voted for this card.'],
      ['de', 'Niemand hat für diese Karte gestimmt.'],
      ['it', 'Nessuno ha votato per questa carta'],
      ['es', 'Nadie ha votado por esta carta'],
      ['ca', 'Ningú ha votat aquesta carta.'],
      ['pt', 'Ninguém votou nesta carta.'],
      ['th', 'ไม่มีใครโหวตให้การ์ดนี้'],
      ['tr', 'Kimse bu karta oy vermedi.'],
      ['ja', 'このカードに投票した人はいません。'],
    ]),
  ],
  [
    'username.chose-username',
    new Map([
      ['fr', 'Choisissez un pseudo'],
      ['en', 'Choose a username'],
      ['de', 'Wähle ein Pseudo'],
      ['it', 'Scegliete un pseudonimo'],
      ['es', 'Escoge un nombre de usuario'],
      ['ca', "Escolliu un nom d'usuari"],
      ['pt', 'Escolha um nome de usuário'],
      ['th', 'เลือกชื่อผู้ใช้'],
      ['tr', 'Bir kullanıcı adı seç'],
      ['ja', 'ユーザー名を選択'],
    ]),
  ],
  [
    'username.placeholder',
    new Map([
      ['fr', 'pseudo'],
      ['en', 'username'],
      ['de', 'pseudo'],
      ['it', 'pseudonimo'],
      ['es', 'nombre de usuario'],
      ['ca', "nom d'usuari"],
      ['pt', 'nome de usuário'],
      ['th', 'ชื่อผู้ใช้'],
      ['tr', 'kullanıcı adı'],
      ['ja', 'ユーザー名'],
    ]),
  ],
  [
    'username.error-cant-be-empty',
    new Map([
      ['fr', 'Le pseudo ne peut pas être vide'],
      ['en', "The username can't be empty"],
      ['de', 'Das Pseudo kann nicht leer sein'],
      ['it', 'Il pseudonimo non può essere vuoto'],
      ['es', 'El nombre de usuario no puede estar en blanco'],
      ['ca', "El nom d'usuari no es pot deixar buit."],
      ['pt', 'O nome de usuário não pode estar vazio.'],
      ['th', 'ชื่อผู้ใช้ต้องไม่ว่างเปล่า'],
      ['tr', 'Kullanıcı adı boş olamaz.'],
      ['ja', 'ユーザー名は空にできません。'],
    ]),
  ],
  [
    'game-waiting.can-be-started',
    new Map([
      ['fr', 'Vous pouvez maintenant lancer la partie'],
      ['en', 'You can now start the game'],
      ['de', 'Sie können jetzt das Spiel starten'],
      ['it', 'Adesso puo avviare la partita'],
      ['es', 'Ya puedes comenzar la partida'],
      ['ca', 'Ja podeu començar la partida.'],
      ['pt', 'Você pode iniciar o jogo agora'],
      ['th', 'คุณสามารถเริ่มเกมได้แล้ว'],
      ['tr', 'Artık oyunu başlatabilirsin'],
      ['ja', 'ゲームを開始できます'],
    ]),
  ],
  [
    'game-waiting.waiting-to-be-started',
    new Map([
      ['fr', 'En attente du lancement de la partie'],
      ['en', 'Waiting for the game to start'],
      ['de', 'Warten auf den Start des Spiels'],
      ['it', 'Aspettando di avviare la partita'],
      ['es', 'Esperando que comience la partida'],
      ['ca', "S'espera que comenci la partida."],
      ['pt', 'Aguardando o início do jogo'],
      ['th', 'กำลังรอให้เกมเริ่ม'],
      ['tr', 'Oyunun başlaması bekleniyor'],
      ['ja', 'ゲーム開始を待っています'],
    ]),
  ],
  [
    'game-waiting.waiting-for-players',
    new Map([
      ['fr', 'En attente de joueurs... (3 joueurs minimum, 8 maximum)'],
      ['en', 'Waiting for other players... (3 players min, 8 max)'],
      ['de', 'Warten auf andere Spieler... (3 Spieler min, 8 max)'],
      ['it', 'Aspettando i giocari... (3 giocatori min, 8 max)'],
      ['es', 'Esperando que se unan más jugadores... (3 jugadores min, 8 max)'],
      ['ca', "S'espera que s'hi afegeixin més jugadors... (mínim 3 jugadors i màxim 8)"],
      ['pt', 'Aguardando mais jogadores... (mínimo 3, máximo 8)'],
      ['th', 'กำลังรอผู้เล่นเพิ่ม... (ขั้นต่ำ 3 คน สูงสุด 8 คน)'],
      ['tr', 'Diğer oyuncular bekleniyor... (en az 3, en fazla 8 oyuncu)'],
      ['ja', '他のプレイヤーを待っています… (最小3人、最大8人)'],
    ]),
  ],
  [
    'game-waiting.start-game',
    new Map([
      ['fr', 'Lancer la partie !'],
      ['en', 'Start the game!'],
      ['de', 'Starte das Spiel!'],
      ['it', 'Avviare la partita!'],
      ['es', 'Empezar la partida!'],
      ['ca', 'Comença la partida'],
      ['pt', 'Iniciar o jogo!'],
      ['th', 'เริ่มเกม!'],
      ['tr', 'Oyunu başlat!'],
      ['ja', 'ゲームスタート！'],
    ]),
  ],
  [
    'game-ended.ranking.first',
    new Map([
      ['fr', '1er'],
      ['en', '1st'],
      ['de', '1'],
      ['it', '1°'],
      ['es', '1º'],
      ['ca', '1r'],
      ['pt', '1º'],
      ['th', 'ที่ 1'],
      ['tr', '1.'],
      ['ja', '1位'],
    ]),
  ],
  [
    'game-ended.ranking.other',
    new Map([
      ['fr', '{$}ème'],
      ['en', '{$}'],
      ['de', '{$}'],
      ['it', '{$}°'],
      ['es', '{$}º'],
      ['ca', '{$}'],
      ['pt', '{$}º'],
      ['th', 'ที่ {$}'],
      ['tr', '{$}.'],
      ['ja', '{$}位'],
    ]),
  ],
  [
    'game-ended.thanks',
    new Map([
      ['fr', "🎉 Merci d'avoir joué 🙌 !"],
      ['en', '🎉 Thanks for playing 🙌 !'],
      ['de', '🎉 Danke fürs Spielen 🙌 !'],
      ['it', '🎉 Grazie per aver giocato 🙌 !'],
      ['es', '🎉 Gracias por jugar 🙌 !'],
      ['ca', '🎉 Gràcies per jugar 🙌 !'],
      ['pt', '🎉 Obrigado por jogar 🙌 !'],
      ['th', '🎉 ขอบคุณที่มาร่วมเล่น 🙌 !'],
      ['tr', '🎉 Oynadığın için teşekkürler 🙌 !'],
      ['ja', '🎉 プレイしてくれてありがとう 🙌 !'],
    ]),
  ],
  [
    'game-ended.survey',
    new Map([
      ['fr', '🙏 Petit sondage rapide ici :) Aidez moi à améliorer Tixid Online 🙏'],
      ['en', '🙏 Quick survey here :) Help me improve Tixid Online 🙏'],
      ['de', '🙏 Schnell Umfrage hier :) Hilf mir, Tixid Online zu verbessern 🙏'],
      ['it', '🙏 Quick survey here :) Help me improve Tixid Online 🙏'],
      ['es', '🙏 Encuesta rápida aquí :) Ayúdame a mejorar Tixid Online 🙏'],
      ['ca', "🙏 Enquesta ràpida aquí :) Ajuda'm a millorar Tixid Online 🙏"],
      ['pt', '🙏 Pesquisa rápida aqui :) Me ajude a melhorar o Tixid Online 🙏'],
      ['th', '🙏 แบบสอบถามสั้นๆ ตรงนี้ :) ช่วยปรับปรุง Tixid Online ให้ดีขึ้น 🙏'],
      ['tr', "🙏 Hızlı bir anket :) Tixid Online'ı geliştirmeme yardım et 🙏"],
      ['ja', '🙏 クイックアンケートにご協力ください :) Tixid Onlineの改善にお力添えを 🙏'],
    ]),
  ],
  [
    'clue.being-the-storyteller',
    new Map([
      ['fr', "Vous avez donné l'indice : "],
      ['en', 'You gave the following clue: '],
      ['de', 'Sie gaben den folgenden Hinweis: '],
      ['it', 'Hai dato il seguente indizio: '],
      ['es', 'Has dado la siguiente pista: '],
      ['ca', 'Heu donat aquesta pista: '],
      ['pt', 'Você deu a seguinte dica: '],
      ['th', 'คุณให้คำใบ้ว่า: '],
      ['tr', 'Verdiğin ipucu: '],
      ['ja', 'あなたが出したヒント： '],
    ]),
  ],
  [
    'clue.being-a-player',
    new Map([
      ['fr', "{$} a donné l'indice : "],
      ['en', '{$} gave the following clue : '],
      ['de', '{$} gab den folgenden Hinweis : '],
      ['it', '{$} ha dato il seguente indizio: '],
      ['es', '{$} ha dado la siguiente pista: '],
      ['ca', '{$} ha donat aquesta pista: '],
      ['pt', '{$} deu a seguinte dica: '],
      ['th', '{$} ให้คำใบ้ว่า: '],
      ['tr', '{$} şu ipucunu verdi: '],
      ['ja', '{$}が出したヒント： '],
    ]),
  ],
  [
    'titled-card-grid.your-hand',
    new Map([
      ['fr', 'Votre main'],
      ['en', 'Your hand'],
      ['de', 'Deine Hand'],
      ['it', 'Sua mano'],
      ['es', 'Tu mano'],
      ['ca', 'La vostra mà'],
      ['pt', 'Sua mão'],
      ['th', 'ไพ่ในมือของคุณ'],
      ['tr', 'Elindeki kartlar'],
      ['ja', '手札'],
    ]),
  ],
  [
    'titled-card-grid.chose-card',
    new Map([
      ['fr', 'Choisissez une carte'],
      ['en', 'Choose a card'],
      ['de', 'Wähle eine Karte'],
      ['it', 'Scegli una carta'],
      ['es', 'Escoge una carta'],
      ['ca', 'Escolliu una carta.'],
      ['pt', 'Escolha uma carta'],
      ['th', 'เลือกการ์ดหนึ่งใบ'],
      ['tr', 'Bir kart seç'],
      ['ja', 'カードを選んでください'],
    ]),
  ],
  [
    'titled-card-grid.chose-two-cards',
    new Map([
      ['fr', 'Choisissez deux cartes'],
      ['en', 'Choose two cards'],
      ['de', 'Wähle zwei Karten'],
      ['it', 'Scegli due carte'],
      ['es', 'Escoge dos cartas'],
      ['ca', 'Escolliu dues cartes.'],
      ['pt', 'Escolha duas cartas'],
      ['th', 'เลือกการ์ดสองใบ'],
      ['tr', 'İki kart seç'],
      ['ja', '2枚のカードを選んでください'],
    ]),
  ],
  [
    'titled-card-grid.find-card',
    new Map([
      ['fr', 'Retrouvez la carte de {$}'],
      ['en', "Find {$}'s card"],
      ['de', 'Finde {$}s Karte'],
      ['it', 'Ritrova la carta di {$}'],
      ['es', 'Encuentra la carta de {$}'],
      ['ca', 'Trobeu la carta de {$}'],
      ['pt', 'Encontre a carta de {$}'],
      ['th', 'หาการ์ดของ {$}'],
      ['tr', '{$} kartını bul'],
      ['ja', '{$}のカードを見つけてください'],
    ]),
  ],
  [
    'titled-card-grid.vote-results',
    new Map([
      ['fr', 'Résultat des votes'],
      ['en', 'Vote results'],
      ['de', 'Ergebnis der Stimmen'],
      ['it', 'Risultato dei votazioni'],
      ['es', 'Resultado de las votaciones'],
      ['ca', 'Resultat de la votació'],
      ['pt', 'Resultado da votação'],
      ['th', 'ผลการโหวต'],
      ['tr', 'Oylama sonuçları'],
      ['ja', '投票結果'],
    ]),
  ],
  [
    'titled-card-grid.see-leaderboard',
    new Map([
      ['fr', 'Voir le classement final !'],
      ['en', 'See the leaderboard !'],
      ['de', 'Siehe die endgültige Rangliste !'],
      ['it', 'Vedere il risultato finale'],
      ['es', 'Ver la tabla de clasificación'],
      ['ca', 'Mostra la taula de classificació'],
      ['pt', 'Ver a classificação final!'],
      ['th', 'ดูตารางคะแนนสุดท้าย!'],
      ['tr', 'Son sıralamayı gör!'],
      ['ja', '最終順位を見る！'],
    ]),
  ],
  [
    'titled-card-grid.go-to-next-turn',
    new Map([
      ['fr', 'Passer au prochain tour'],
      ['en', 'Go to next turn'],
      ['de', 'Siehe die endgültige Rangliste'],
      ['it', 'Passare al prossimo giro'],
      ['es', 'Pasar al siguiente turno'],
      ['ca', 'Passa al següent torn'],
      ['pt', 'Ir para o próximo turno'],
      ['th', 'ไปรอบถัดไป'],
      ['tr', 'Sonraki tura geç'],
      ['ja', '次のラウンドへ'],
    ]),
  ],
]);

export const I18nProvider = ({ defaultLanguage = 'en', children }) => {
  const [language, setLanguage] = useState(defaultLanguage);
  const t = useCallback(
    (name, param = '') => {
      if (!catalog.has(name)) {
        console.error(`${name} not found in catalog`);
        return name;
      }
      if (!catalog.get(name).has(language)) {
        console.error(`${language} not found in catalog for ${name}`);
        return name;
      }
      return catalog.get(name).get(language).replace('{$}', param);
    },
    [language]
  );

  return (
    <I18nTranslateContext.Provider value={t}>
      <I18nLanguageContext.Provider value={{ language, setLanguage }}>{children}</I18nLanguageContext.Provider>
    </I18nTranslateContext.Provider>
  );
};
